@font-face {
font-family: '__polySansWide_9ace53';
src: url(/_next/static/media/efca904ed51b8362-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__polySansWide_9ace53';
src: url(/_next/static/media/af4ac765f65445ae-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__polySansWide_9ace53';
src: url(/_next/static/media/201367946b752e68-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__polySansWide_Fallback_9ace53';src: local("Arial");ascent-override: 82.52%;descent-override: 15.72%;line-gap-override: 0.00%;size-adjust: 122.15%
}.__className_9ace53 {font-family: '__polySansWide_9ace53', '__polySansWide_Fallback_9ace53'
}

